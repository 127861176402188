import React from "react";


const StudentItem = props => {

    const {up, down, link} = props;

    return <li className={"StudentsBox"}>
        <a href={link} target={"_blank"}>
        {up}
        {down}
        </a>
    </li>
}

export default StudentItem;