import React, {useEffect, useState} from "react";

import quote from '../../images/quote.png'

const Card = props => {

    const { comment, author, add_text, cl_comment} = props.data;
    const [direction, setDirection] = useState(false); // false - front, true - back

    const handleClick = () => {
        setDirection(!direction);
    }


    return (
        <div className={`CommentCards__card card ${direction ? "backCard" : ""}`} onClick={handleClick}>
            <div className={`content `}>
                <article className={`CommentCards__card--front front`}>

                    <img className={"CommentCards__quote"} src={quote} alt={"Quote"} />
                    <h5>{comment}</h5>
                    <span className={"CommentCards__author"}>{author}</span>
                    <span className={"CommentCards__addText"}>{add_text} </span>
                    <div className="welcome__arrows welcome__arrows__container">
                        <span></span><span></span><span></span>
                    </div>

                </article>
                <article className={`CommentCards__card--back  back`}>
                    <p>{cl_comment}</p>
                </article>
            </div>
        </div>
    )
}

export default Card;