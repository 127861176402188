import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Link, animateScroll as scroll } from "react-scroll"
import Select from "react-select"

const Form = props => {
  const { data } = props
  const dispatch = useDispatch()
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [city, setCity] = useState(null)
  const [experience, setExperience] = useState(null)
  const [agree, setAgree] = useState(false)
  const [info, setInfo] = useState("")

  const handleShowTest = () => {
    if (
      validateEmail(email) &&
      phone !== "" &&
      city &&
      experience !== null &&
      agree
    ) {
      setInfo("")
      dispatch({
        type: "START_TEST",
      })
      dispatch({
        type: "SAVE_USER",
        email: email,
        phone: phone,
        city: city.value,
        experience: experience,
      })
      window !== undefined && window.test(email, phone, city.label, experience, window.location.hostname)
    } else {
      setInfo("Wypełnij wszystkie pola")
      return
    }
  }

  const validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const handleChangeAgree = () => {
    setAgree(!agree)
  }

  const handleChangeEmail = e => {
    setEmail(e.target.value)
  }

  const handleChangePhone = e => {
    setPhone(e.target.value)
  }

  const handleChangeCity = selectedOption => {
    setCity(selectedOption)
  }

  const cityOptions = [
    { value: "warsaw", label: "Warszawa" },
    { value: "wroclaw", label: "Wrocław" },
    { value: "cracow", label: "Kraków" },
    { value: "poznan", label: "Poznań" },
    { value: "katowice", label: "Katowice" },
    { value: "undecided", label: "Nie wiem" },
    { value: "remotely", label: "Zdalnie (online)" },
  ]

  return (
    <section className={`FormContainer`}>
      <div dangerouslySetInnerHTML={{ __html: data }}></div>
      <div className={"FormContainer__inputs"}>
        <div className="FormContainer__row">
          <input
            type={"email"}
            className={"FormContainer__inputs--field"}
            placeholder={"e-mail *"}
            value={email}
            name={"email"}
            onChange={handleChangeEmail}
          />
          <input
            type={"text"}
            className={"FormContainer__inputs--field"}
            placeholder={"nr telefonu *"}
            value={phone}
            name={"phone"}
            onChange={handleChangePhone}
          />
        </div>
        <div className="FormContainer__row">
          <Select
            options={cityOptions}
            placeholder="gdzie chcesz się uczyć? *"
            value={city}
            name="city"
            onChange={handleChangeCity}
            className="FormContainer__inputs--select"
            classNamePrefix="input-select"
            lebel="gdzie chcesz się uczyć? *"
          />
          <div className="FormContainer__radio-wrapper">
            <p>Czy masz już jakieś doświadczenie w IT?</p>
            <div className="FormContainer__radios">
              <label>
                <input
                  type="radio"
                  name="experience"
                  value={true}
                  onClick={() => setExperience(true)}
                />
                <span>TAK</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="experience"
                  value={false}
                  onClick={() => setExperience(false)}
                />
                <span>NIE</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className={"FormContainer__agree"}>
        <p>
          {" "}
          * Wypełniłeś&#8725;aś formularz kontaktowy, co oznacza, że jesteś
          zainteresowany&#8725;a naszą ofertą i oczekujesz kontaktu z naszej
          strony. Przed przesłaniem nam Twoich danych osobowych, zapoznaj się z
          dokładniejszymi{" "}
          <a href="https://coderslab.pl/pl/informacja" target={"_blank"}>
            informacjami
          </a>{" "}
          na temat tego, jak będziemy je przetwarzać. Chcielibyśmy móc
          przetwarzać Twoje dane w celu przekazywania Ci informacji na temat
          naszych usług. Jeśli nie będziesz już tym zainteresowany&#8725;a
          zawsze możesz się temu sprzeciwić. Spowoduje to, że zaprzestaniemy
          przetwarzania Twoich danych osobowych w tym celu.
        </p>
        <div className={"check"}>
          <label>
            <input
              type="checkbox"
              name={`agree`}
              value={agree}
              onChange={handleChangeAgree}
            />
            <span>
              Potwierdzam, że przeczytałem{" "}
              <a href="https://coderslab.pl/pl/informacja" target={"_blank"}>
                informacje
              </a>{" "}
              dot. przetwarzania danych.
            </span>
          </label>
        </div>

        <div className={"infoUser"}>{info}</div>
      </div>
      <Link
        onClick={handleShowTest}
        className={`btn btn__orange`}
        to={"section4"}
        spy={true}
        smooth={true}
        offset={100}
        duration={500}
      >
        {" "}
        Wyślij{" "}
      </Link>
    </section>
  )
}

export default Form
