import React from "react";
import logo from '../../images/logo2.png'


const Footer = ({title, contact, socials}) => {

    const linksClass = ["fb", "insta", "yt" , "in"];

    const links = socials.map((el,i) => {
        return <li key={i}>
            <a target="_blank" href={el.link} className={`${linksClass[i]} socialLink`}> </a>
        </li>
    })

    return <footer id={"section6"}>
        <section className={"FooterContact mainWidth-3"}>
            <div className="FooterContact__title" dangerouslySetInnerHTML={{__html: title}}></div>
            <div className="FooterContact__contact" dangerouslySetInnerHTML={{__html: contact}}></div>
        </section>
        <section className={"FooterSocials"}>
            <img src={logo} alt={"Coders Lab logo"}/>
            <ul className={"FooterSocials__links mainWidth-3"}>
                {links}
            </ul>
        </section>
        <span className={"FooterCopy"}>Copyright @ Coders Lab {(new Date).getFullYear()} </span>
    </footer>
}

export default Footer;
