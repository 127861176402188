import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";


const AnimationRoad = ( {showRoad, nmbOfPoints} ) => {

    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (nmbOfPoints >= 9) {
            setHeight(771)
        } else {
            setHeight(625)
        }
    }, [showRoad])


    const roadLong = keyframes`
    0% { height: 0px; }
    100% {  height: ${height}px; } `;

    const animation = props => css`${roadLong} 2s 1 forwards`;
    const Road = showRoad ? styled.div`animation: ${animation}` : styled.div``;


    return <Road className={'road'} />
}

export default AnimationRoad;