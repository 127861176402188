import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
import { Link, animateScroll as scroll } from "react-scroll";
import decodedWords from "../../reducers/testAnswersMap";


const Test = ({singlePage}) => {

    const {
        testTitle,
        testDetails,
        numberOfQuestions,
        questions,
        testAnswersMap,
    } = useSelector(state => ({
        ...state.testReducer,
    }));

    const [actualQuest, setActualQuest] = useState(0);
    const [inputValue, setInputValue] = useState(-1);
    const [questWidth, setQuestWidth] = useState(() => {
        if (window.innerWidth <= 779 && window.innerWidth > 640) {
            return window.innerWidth - 59
        } else if (window.innerWidth <= 640) {
            return 331;
        } else {
            return 640;
        }
    });

    // const
    const dispatch = useDispatch();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 779) {
                setQuestWidth(window.innerWidth - 44)
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    const generateUserProffesion = () => {
        let areaExp = [];
        let areaMind = [];
        let areaDec = [];
        let areaPer = [];

        testAnswersMap.map(el => {
            if (el.area === "expression") {
                areaExp.push(el)
            } else if (el.area === "mind") {
                areaMind.push(el)
            } else if (el.area === "decisions") {
                areaDec.push(el)
            } else if (el.area === "perception") {
                areaPer.push(el)
            }
        })

        const sumExp = areaExp.reduce((a, b) => {
            return {value: a.value + b.value}
        })
        const sumMind = areaMind.reduce((a, b) => {
            return {value: a.value + b.value}
        })
        const sumDec = areaDec.reduce((a, b) => {
            return {value: a.value + b.value}
        })
        const sumPer = areaPer.reduce((a, b) => {
            return {value: a.value + b.value}
        })

        let Letter1 = sumExp.value <= 1 ? "E" : "I" // if sumExp < 2 that means was more A answers
        let Letter2 = sumMind.value <= 1 ? "N" : "S" // if sumMind < 2 that means was more A answers
        let Letter3 = sumDec.value <= 1 ? "F" : "T" // if sumDec < 2 that means was more A answers
        let Letter4 = sumPer.value <= 1 ? "W" : "P" // if sumPer < 2 that means was more A answers


        const word = Letter1 + Letter2 + Letter3 + Letter4;
        const obj = decodedWords.filter(el => el.words.find(w => w === word))

        return {
            id: obj[0].type,
            name: obj[0].profName
        }
    }

    const handleChangeValue = (e) => {
        setInputValue(e.target.value);
    }

    const handlePrevQuestion = () => {
        setActualQuest(current => current > 0 ? current - 1 : 0)
    }

    const handleNextQuestion = (e, questionId) => {
        setActualQuest(current => current < numberOfQuestions ? current + 1 : numberOfQuestions);
        dispatch({
            type: "ADD_ANSWER",
            answerObj: { value: ~~inputValue, questionId: actualQuest + 1}
        })
        setInputValue(-1)
    }

    const handleFinishTest = () => {
        dispatch({
            type: "ADD_ANSWER",
            answerObj: { value: ~~inputValue, questionId: actualQuest + 1}
        })

        const obj = generateUserProffesion();

        dispatch({
            type: "FINISH_TEST",
            obj: obj
        })

        window !== undefined && window.sendTestResult(obj.name);
    }

    const handleCloseTest = () => {
        dispatch({
            type: "CLOSE_TEST"
        })
    }

    const testList = questions.map((el, i) => {
        const answers = el.answers.map((an, j) => {
            return <li key={j} className={"QuestionsContainer__answer"}>
                <label>
                    <input type="radio" name={`radio${i}`} value={j} onChange={ handleChangeValue }/>
                    <span>{ j % 2 === 0 ? "A." : "B."} {an.answer} </span>
                </label>
            </li>
        })
        return <li key={i} className={"QuestionsContainer__list--question"}>
            <span className={"QuestionsContainer__questionLabel"}> {el.question} </span>
            <ul className={"QuestionsContainer__answersList"}>
                {answers}
            </ul>
        </li>
    });

    const finishBtn = actualQuest === numberOfQuestions - 1
        ?
        <Link className={`btn btn__orange btnsContainer--next ${inputValue === -1 ? "notActive": ""}`}
              onClick={handleFinishTest}
              to={"finishScreen"}
              spy={true}
              smooth={true}
              duration= {500}
        > Wyślij
        </Link>
        :
        <button className={`btn btn__orange btnsContainer--next ${inputValue === -1 ? "notActive": ""}`}
                style={{display: actualQuest < numberOfQuestions - 1 ? 'block' : 'none'}}
                onClick={handleNextQuestion}> Następne pytanie
        </button>

    return (
        <section className={`TestContainer`}>
            <article className={"mainWidth-4 TestContainer__box"}>
                {!singlePage && <span className={"closeTest"} onClick={handleCloseTest}> x </span>}
                <h2>{testTitle}</h2>
                <p> {testDetails} </p>
                <div className={"QuestionsContainer"}>
                    <span className="QuestionsContainer__label">
                        PYTANIE {actualQuest + 1}/{numberOfQuestions}:
                    </span>
                    <ul className="QuestionsContainer__list"
                        style={{width: questWidth * numberOfQuestions, left: -questWidth * actualQuest}}
                    >
                        {testList}
                    </ul>
                    <div className={"btnsContainer"}>
                        <button className={"btn btn__orange btnsContainer--prev"}
                                style={{display: actualQuest > 0 ? 'block' : 'none'}}
                                onClick={handlePrevQuestion}
                        > Poprzednie pytanie
                        </button>

                        {finishBtn}

                    </div>
                </div>
            </article>
        </section>
    )
}

export default Test;