import React, { useState, useEffect } from "react";
import {useSelector, useDispatch } from 'react-redux';
import Road from './Road'
import Markers from './Markers'


const CarierPath = props => {

    const {title, paths} = props;
    const [activeSvgInd, setActiveSvg] = useState(0)
    const imgNames = ["front", "back", "tester", "ux"];
    const imgDescription = ["Programista front-end", "Programista back-end", "Tester", "UX Designer"];
    const dispatch = useDispatch();

    const [showRoad, setShowRoad] = useState(false);
    const [showBulb, setShowBulb] = useState(false);
    const [showMarkers, setShowMarkers] = useState(false);

    const {
        pathType
    } = useSelector(state => ({
        ...state.testReducer,
    }));


    useEffect(() => {
        let roadID, markerID;
        if (!showRoad) {
            setShowBulb(true);
            roadID = setTimeout(() => {
                setShowRoad(true);
                clearTimeout(roadID)
            }, 1000);
        }

        if (!showMarkers) {
            markerID = setTimeout(() => {
                setShowMarkers(true);
                clearTimeout(markerID);
            }, 100)
        }

        setActiveSvg(paths.findIndex(el=> el["wordpress_id"] ===pathType))

        return () =>  {
            clearTimeout(roadID);
            clearTimeout(markerID);
        }
    }, [showRoad, pathType])

    const handleActiveSvg = (activeSvg, i) => {
        dispatch({
            type: "SET_ACTIVE_SVG",
            activeSvg: activeSvg
        })
        setActiveSvg(i);
        setShowRoad(false);
        setShowBulb(false);
        setShowMarkers(false);
    }


    const menu = imgNames.map((el,i) => {
        return <li key={i}
                   onClick={ e => handleActiveSvg(imgNames[i], i)}
                   className={`menuItem ${activeSvgInd === i ? "active" : "blurBtn"}`}
                >
            <div className={`${imgNames[i]}Image menuImage`} />
            <span className={"description"}>{`${imgDescription[i]}`}</span>
        </li>
    })

    const activePath = activeSvgInd === -1 ? 0 : activeSvgInd;
    const path = <div>
        <Road showRoad={showRoad} nmbOfPoints={paths[activePath].points.length}/>
        <div className={'path__content'}>
            <div className={`bulb stdElement ${showBulb ? "animateBulb": ""}`}/>
            <div className={'path__info'}>
                { showMarkers && <Markers points={paths[activePath].points}
                                          showBulb={showBulb}
                                          id={paths[activePath]["wordpress_id"]}/> }
            </div>
            <div className={'target stdElement'}/>
        </div>
    </div>

    return (
        <section className={`CarierPath`}>
            <div className={"CarierPath__bg--top"} />
            <article className={"CarierPath__content mainWidth-1"}>
                <header>
                    <h2> {title} dla zawodu {paths[activePath].pathTitle}</h2>
                    <ul className={'CarierPath__menu'}>
                        {menu}
                    </ul>
                </header>
                <section className={`path ${paths[activePath].points.length >=9 ? 'longPath' : 'shortPath'}`}
                         style={{height: paths[activePath].points.length >=9 ? '771px' : '625px'}}>
                    {path}
                </section>
            </article>
            <div className={"CarierPath__bg--bottom"} />
        </section>
    )
}

export default CarierPath;