import React, {useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';

const FinishScreen = () => {

    const {
        userProf,
    } = useSelector(state => ({
        ...state.testReducer,
    }));

    useEffect(()=>{
    }, [userProf])


    return (
        <section className={`FinishScreen`}>
            <p> Pomyśl o rozwoju kariery jako </p>
            <h2> {userProf.name} </h2>
        </section>
    )
}

export default FinishScreen;