import React, {useState, useEffect, useRef} from "react";


const MarkersItem = ({point, i, addAnimation, showMarkers, date}) => {


    useEffect(() => {
    }, [showMarkers])

    const side = i % 2 === 0 ? "leftSide" : 'rightSide';
    return <div className={`path__description  ${side} ${addAnimation ? "animateBulb" : ""}
                                    ${showMarkers !== 0 && showMarkers >= i ? "animateMarker" : ""}
                                    `}
    >
        <span className={'placeName'}>{point.placeName}</span>
        <span className={'addInfo'}>{point.addInfo}</span>
        <span className={'date'}>{date}</span>
    </div>
}

export default MarkersItem;