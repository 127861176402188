import React, {useState} from "react";
import StudentItem from "./StudentItem";
import VioletBell from "./VioletBell";


const Students = props => {

    const {title, data, link} = props;
    const [colorHover, setColorHover] = useState(-1);

    const handleHover = (i) => {
        setColorHover(i);
    }

    const handleLeave = () => {
        setColorHover(-1);
    }

    const boxes = data.map((el, i) => {

        const imgDirection = i % 2 === 0 ? "imgUp" : "imgDown";
        let up, down;

        if (imgDirection === "imgUp") {
            up =  <div className={`StudentsBox__image`}
                       style={{backgroundImage: `url(${el.image["source_url"]})`}}
                       onMouseEnter={e=> handleHover(i)} onMouseLeave={handleLeave}>
            </div>

            down = <div className={`StudentsBox__info ${colorHover === i ? "violetColor"  : ""}`}>
                <h5>{el.name}</h5>
                <span className={"description bell"}>{el.info}</span>
                <span className={"description"}> jest </span>

                <span className={"who_is"}>{el["who_is"]}</span>
            </div>
        } else {
            down = <div className={"StudentsBox__image"} style={{backgroundImage: `url(${el.image["source_url"]})`}}
            onMouseEnter={e=> handleHover(i)} onMouseLeave={handleLeave}>
            </div>

            up = <div className={`StudentsBox__info ${colorHover === i ? "violetColor"  : ""} `}>
                <h5>{el.name}</h5>
                <span className={"description bell"}>{el.info}</span>

                <span className={"description"}> jest </span>
                <span className={"who_is"}>{el["who_is"]}</span>
            </div>
        }

        return <StudentItem up={up} down={down} key={i} link={el.link}/>;
    })


    return <section className={"Students"} id={"section5"}>
        <article className={"mainWidth-3"}>
            <h2> {title} </h2>
            <ul className={"boxes"}>
                {boxes}
            </ul>
            <a href={link} className={"btn btn__violet"} target={"_blank"}> Zobacz więcej absolwentów CL
            </a>
        </article>
    </section>
}

export default Students;