export default [
    {
        id: "front",
        pathTime: [
            {
                index: 0,
                id: "month",
                many: 0,
            },
            {
                index: 1,
                id: "month",
                many: 1,
            },
            {
                index: 2,
                id: "week",
                many: 2,
            },
            {
                index: 3,
                id: "week",
                many: 4,
            },
            {
                index: 4,
                id: "month",
                many: 6
            },
            {
                index: 5,
                id: "month",
                many: 3
            },
            {
                index: 6,
                id: "month",
                many: 24
            },
            {
                index: 7,
                id: "month",
                many: 48
            },
            {
                index: 8,
                id: "month",
                many: 24
            }
        ]
    },
    {
        id: "back",
        pathTime: [
            {
                index: 0,
                id: "month",
                many: 0,
            },
            {
                index: 1,
                id: "month",
                many: 1,
            },
            {
                index: 2,
                id: "week",
                many: 2,
            },
            {
                index: 3,
                id: "week",
                many: 4,
            },
            {
                index: 4,
                id: "month",
                many: 6
            },
            {
                index: 5,
                id: "month",
                many: 3
            },
            {
                index: 6,
                id: "month",
                many: 24
            },
            {
                index: 7,
                id: "month",
                many: 48
            },
            {
                index: 8,
                id: "month",
                many: 24
            }
        ]
    },
    {
        id: "tester",
        pathTime: [
            {
                index: 0,
                id: "month",
                many: 0,
            },
            {
                index: 1,
                id: "month",
                many: 1,
            },
            {
                index: 2,
                id: "week",
                many: 2,
            },
            {
                index: 3,
                id: "week",
                many: 3,
            },
            {
                index: 4,
                id: "week",
                many: 6
            },
            {
                index: 5,
                id: "month",
                many: 3
            },
            {
                index: 6,
                id: "month",
                many: 12
            },
            {
                index: 7,
                id: "month",
                many: 36 //3 lata
            },
        ]
    },
    {
        id: "ux",
        pathTime: [
            {
                index: 0,
                id: "month",
                many: 0,
            },
            {
                index: 1,
                id: "month",
                many: 1,
            },
            {
                index: 2,
                id: "week",
                many: 2,
            },
            {
                index: 3,
                id: "week",
                many: 2,
            },
            {
                index: 4,
                id: "month",
                many: 6
            },
            {
                index: 5,
                id: "month",
                many: 3
            },
            {
                index: 6,
                id: "month",
                many: 24
            },
            {
                index: 7,
                id: "month",
                many: 36 //3 lata
            },
        ]
    }

];