import React, {useState} from "react";
import { Link, animateScroll as scroll } from "react-scroll";

import Card from './Card'
import logo from '../../images/logo.png'

const Header = props => {

    const [menuVisible, setMenuVisible] = useState(false);

    const cards = props.cards && props.cards.map((el, i) => {
        return <Card key={i}
                     data={el}
        />
    })

    const handleMenu = () => {
        setMenuVisible(!menuVisible);
    }

    const handleClickLink = (e) => {
        e.preventDefault();
        setMenuVisible(false);
    }

    const linksText = [
        { text: "Dlaczego warto pomyśleć o rozwoju w IT?", id: "section1"},
        {text: "Praca w IT to nie tylko umiejętności techniczne", id: "section2"},
        {text: "IT to różne zawody", id: "section3"},
        {text: "Który zawód jest dla Ciebie?", id: "section4"},
        {text: "Zobacz jak oni to zrobili", id: "section5"},
        {text: "Kontakt", id: "section6"}
    ]

    const links = linksText.map((el,i) => {
        return <Link key={i}
                     to={el.id}
                     activeClass="active"
                     spy={true}
                     smooth={true}
                     offset={-70}
                     duration= {500}
                     onClick={handleClickLink}
        >
            {el.text}
        </Link>
    })

    return (
        <section className={"mainHeader"}>
            <nav className={"mainMenu"}>
                <div className={`burger-button ${menuVisible ? "active" : ""}`} onClick={handleMenu}>
                    <span className="burger-top"></span>
                    <span className="burger-middle"></span>
                    <span className="burger-bottom"></span>
                </div>
                <div className={`burger-menu ${menuVisible ? "active" : ""}`}>
                    {links}
                </div>
            </nav>
            <div className={"mainHeader__image1"}></div>
            <article className={"mainWidth-1 mobileHeight"}>
                <header>
                    <img src={logo} alt={"Coders Lab logo"}/>
                    <h2>{props.title}</h2>
                </header>

                <ul className={"CommentCards"} id={"section1"}>
                    {cards}
                </ul>
            </article>

        </section>
    )
}

export default Header;