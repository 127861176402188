import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';

//components
import Header from '../components/Header/Header'
import VioletBell from "../components/Content/VioletBell";
import Proffesions from "../components/Content/Proffesions";
import CarierPath from "../components/Content/CarierPath";
import Form from "../components/Test/Form";
import Test from "../components/Test/Test";
import FinishScreen from "../components/Test/FinishScreen";
import Footer from "../components/Footer/Footer";
import Students from "../components/Content/Students";


const App = props => {
    const homePage = props.data;
    const [showForm, setShowForm] = useState(false);
    const dispatch = useDispatch();

    const {
        testVisible,
        finishScreen,
        carierPathVisible,
    } = useSelector(state => ({
        ...state.testReducer,
    }));

    useEffect(() => {
        dispatch({
            type: 'SET_TEST',
            testTitle: homePage.testTitle,
            testDetails: homePage.test_desc,
            questions: homePage.questions
        })
    }, [showForm])

    const handleShowForm = () => {
        setShowForm(true)
    }

    const handleShowPath = (e) => {
        e.preventDefault();

        dispatch({
            type: 'SHOW_PATH',
            idPath: 'front',
        })
    }

    if (homePage === undefined) return null;

    return (
        <div>
            <section>
                <Header title={homePage.title}
                        cards={homePage.cards}/>
                <VioletBell positionImage={"right"}>
                    <h3 id={"section2"}>{homePage.violetBellTitle}</h3>
                    <div dangerouslySetInnerHTML={{__html: homePage.violetBellText}}></div>
                </VioletBell>

                <Proffesions title={homePage.diffProfTitle} data={homePage.diffProfParts}/>

                <VioletBell positionImage={"left"}
                            otherClass={"center"}>
                    <h3 id={"section4"}>{homePage.violetBellTitle_2}</h3>
                    <button onClick={handleShowForm}
                            className={"btn btn__orange"}> {homePage.violetBellText_2}
                    </button>
                    <div className={"linkNotInterested"}>
                        <a href="" onClick={handleShowPath}>
                            Nie chcę rozwiązywać testu
                        </a>
                    </div>
                    {showForm && !testVisible && <Form data={homePage.violetBellForm_2}/>}
                    {testVisible && <Test/>}
                </VioletBell>
                <div id={"finishScreen"}>{finishScreen && <FinishScreen/>}</div>
                {carierPathVisible && <CarierPath title={homePage.CarierPathTitle} paths={homePage.paths}/>}
                <Students title={homePage.students_title} data={homePage.kursanci} link={homePage["students_link"]}/>

                <Footer title={homePage["footer_title"]}
                        contact={homePage["footer_person_contact"]}
                        socials={homePage["footer_socials"]}/>
            </section>
        </div>
    )
}

export default App;
