import React from "react";

const VioletBell = props => {
    const {positionImage, otherClass} = props
    return (
        <section className={`VioletBell ${otherClass !== undefined ? otherClass : ""}`}>
            <article className={"mainWidth-2"}>
                {props.children}
            </article>
            <div className={`VioletBell__image VioletBell__image--${positionImage}`}></div>
        </section>
    )
}

export default VioletBell;