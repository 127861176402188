import React, {useState, useEffect} from "react";
import MarkerItem from './MarkerItem';

import markersData from './markersData';

const Markers = ({points, showBulb, id}) => {

    const [showMarkers, setShowMarkers] = useState(-1);


    useEffect(() => {
        let markerID;
        let counter = 0;

        markerID = setInterval(() => {
            if (counter >= 10) {
                clearInterval(markerID);
            }
            counter++;
            setShowMarkers(curr => curr + 1)
        }, 600)

        return () => clearInterval(markerID);

    }, [])


    const newDate = new Date();
    
    const markers = points.map((point, i) => {
        const markerObj = markersData.filter(el=> el.id === id);
        if (markerObj[0].pathTime[i].id === "month") {
            newDate.setMonth(newDate.getMonth() + markerObj[0].pathTime[i].many);
        } else {
            newDate.setDate(newDate.getDate() + markerObj[0].pathTime[i].many * 7);
        }
        const createDate = ('0' + newDate.getDate()).slice(-2) + "." + ('0' + (newDate.getMonth() + 1)).slice(-2) + "." + newDate.getFullYear();
        return <MarkerItem point={point}
                           i={i}
                           key={i}
                           addAnimation={i === 0 && showBulb}
                           showMarkers={showMarkers}
                           date={createDate}
        />
    })

    return <>{markers}</>
}

export default Markers;