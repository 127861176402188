import React, {useState} from "react";

const Proffesions = props => {

    const {title, data} = props;
    const [direction, setDirection] = useState(false); // false - front, true - back
    const [flippedELement, setFlippedELement] = useState(-1);

    const handleClick = (i) => {
        setDirection(!direction);
        setFlippedELement(i)
    }

    const imgNames = ["frontImage", "backImage", "testerImage", "uxImage"]

    const proff = data.map((el, i) => {
        const items = el.list.map((item, j) => {
            return <li key={j}>
                {item.item}
            </li>
        })

        return <div key={i}
                    className={`boxes__item card ${direction && i == flippedELement ? "backCard" : ""}`}
                    onClick={ e => handleClick(i)}
        >
            <div className={`content `}>
                <article className={'front'}>
                    <div className={`boxes__item--header`}>
                        <div className={`${imgNames[i]}`}></div>
                        <div className={"h4_container"}><h4>{el.title}</h4></div>
                    </div>
                    <div className={'boxes__item--front'}>
                        <div dangerouslySetInnerHTML={{__html: el.description}} />
                        <span className={"addText"}> Pożądane cechy ...</span>
                    </div>
                </article>
                <article className={'back'}>
                    <div className={`boxes__item--header`}>
                        <div className={`${imgNames[i]}`}></div>
                        <div className={"h4_container"}><h4>{el.title}</h4></div>
                    </div>
                    <ul className={"boxes__item--back boxes__sublist"}>
                     {items}
                    </ul>
                </article>
            </div>
        </div>
    })

    return (
        <section className={`Proffesions`} id={"section3"}>
            <article className={"mainWidth-3"}>
                <h2> {title} </h2>
                <div className={"boxes"}>
                    {proff}
                    <div className={"imageBottom"}></div>
                </div>
            </article>
        </section>
    )
}

export default Proffesions;